import { FTextField } from '@components/form/FTextField';
import { PhoneNumberInput } from '@components/inputs/PhoneNumberInput';
import { type FC } from 'react';
import { manageDemoAccountControlNames } from './schema';
import { useTranslation } from 'react-i18next';
import PasswordRandomizerField from '../components/PasswordRandomizerField/PasswordRandomizerField';

interface ManageDemoAccountFormProps {
  isEditing?: boolean;
}

const ManageDemoAccountForm: FC<ManageDemoAccountFormProps> = ({
  isEditing,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      <FTextField
        name={manageDemoAccountControlNames.email}
        label="Email"
        id={'email'}
        placeholder={t('sysAdminPage.demo.placeholders.email')}
        disabled={isEditing}
      />
      {!isEditing && (
        <div className="flex items-end gap-2">
          <PasswordRandomizerField
            name={manageDemoAccountControlNames.password}
          />
        </div>
      )}

      <FTextField
        name={manageDemoAccountControlNames.firstName}
        label="First name"
        id={'firstName'}
        placeholder={t('sysAdminPage.demo.placeholders.name')}
      />
      <FTextField
        name={manageDemoAccountControlNames.familyName}
        label="Family name"
        id={'familyName'}
        placeholder={t('sysAdminPage.demo.placeholders.familyName')}
      />
      <PhoneNumberInput
        name={manageDemoAccountControlNames.businessNumber}
        label="Business number"
      />
    </div>
  );
};
export default ManageDemoAccountForm;
