import { MenuOptionsEnum } from 'enums/MenuOptionsEnum';
import React, {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

interface ActiveSideMenuContextType {
  activeMenuOption: MenuOptionsEnum;
  activeRoute: string;
  setActiveRoute: (route: string) => void;
}

const ActiveSideMenuContext = createContext<ActiveSideMenuContextType | null>(
  null
);

export const useActiveSideMenu = (): ActiveSideMenuContextType => {
  const context = useContext(ActiveSideMenuContext);
  if (!context) {
    throw new Error('No context found');
  }
  return context;
};

export const ActiveSideMenuProvider = ({
  children,
}: {
  children: ReactNode;
}): React.JSX.Element => {
  const [activeMenuOption, setActiveMenuOption] = useState<MenuOptionsEnum>(
    MenuOptionsEnum.UNKNOWN_OPTION
  );
  const [activeRoute, setActiveRoute] = useState<string>(() => {
    const storedMenuItem = sessionStorage.getItem('activeMenuItem');
    return storedMenuItem ?? MenuOptionsEnum.UNKNOWN_OPTION;
  });

  useEffect(() => {
    if (activeRoute && activeRoute in MenuOptionsEnum) {
      setActiveMenuOption(
        MenuOptionsEnum[activeRoute as keyof typeof MenuOptionsEnum]
      );
      localStorage.setItem('activeMenuItem', activeRoute);
    }
  }, [activeRoute]);

  return (
    <ActiveSideMenuContext.Provider
      value={{ activeMenuOption, activeRoute, setActiveRoute }}
    >
      {children}
    </ActiveSideMenuContext.Provider>
  );
};
