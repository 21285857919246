import { z } from 'zod';

interface ManageDemoAccountControlNamesType {
  newPassword: 'new_password';
}

export const changeDemoAccountPasswordSchema = z.object({
  new_password: z
    .string()
    .min(10, 'Password must be at least 10 characters long')
    .regex(/[A-Z]/, 'Password must contain at least one capital letter')
    .regex(
      /[a-zA-Z0-9]*[^a-zA-Z0-9]+[a-zA-Z0-9]*/,
      'Password must contain at least one special character'
    ),
});

export const changeDemoAccountPasswordNames: ManageDemoAccountControlNamesType =
  {
    newPassword: 'new_password',
  };

export type ChangeDemoAccountPasswordSchemaType = z.infer<
  typeof changeDemoAccountPasswordSchema
>;
