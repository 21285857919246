/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from 'react';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import Router from 'router/Router/Router';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { AuthProvider } from '../src/hooks/contexts/AuthContext';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider } from '@tanstack/react-query';

import queryClient from './lib/queryClient';

import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { ThemeProvider } from 'context/ThemeProvider/ThemeProvider';
import { ScrollToTop } from '@components/scrollToTop';
import { LocationPathNameProvider } from 'context/LocationProvider/LocationProvider';
import { FeatureFlagProvider } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { ActiveSideMenuProvider } from 'context/ActiveSideMenuProvider/ActiveSideMenuProvider';

function App(): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <AuthProvider>
            <ThemeProvider>
              <FeatureFlagProvider>
                <LocationPathNameProvider>
                  <ScrollToTop />
                  <ActiveSideMenuProvider>
                    <Router />
                  </ActiveSideMenuProvider>
                </LocationPathNameProvider>
              </FeatureFlagProvider>
            </ThemeProvider>
          </AuthProvider>
        </BrowserRouter>
        <ToastContainer />
      </I18nextProvider>
    </QueryClientProvider>
  );
}

export default App;
