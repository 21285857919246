import { FTextField } from '@components/form/FTextField';
import Tippy from '@tippyjs/react';
import { t } from 'i18next';
import { type FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { GiPerspectiveDiceSixFacesRandom } from 'react-icons/gi';
import { generatePassword } from 'utils/password/generatePasswordUtils';

const PasswordRandomizerField: FC<{ name: string }> = ({ name }) => {
  const { setValue } = useFormContext();

  const handleGenerateRandomPassword = (): void => {
    const password = generatePassword();
    if (password) {
      setValue(name, password);
    }
  };
  return (
    <FTextField
      name={name}
      label="Password"
      id={'password'}
      autoComplete="off"
      placeholder={t('sysAdminPage.demo.placeholders.password')}
      addorment={{
        endAddorment: (
          <Tippy content="Randomize">
            <span>
              <GiPerspectiveDiceSixFacesRandom
                className="cursor-pointer"
                onClick={handleGenerateRandomPassword}
                size={27}
              />
            </span>
          </Tippy>
        ),
      }}
    />
  );
};
export default PasswordRandomizerField;
