import { MeasuredVsExpectedChart } from '@components/charts';
import { CardContainer, CardTitle } from 'layout/Card';
import { type FC, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ChartButton } from '../../../../../components/charts/ChartButton';
import { t } from 'i18next';
import { getReadableUnitName } from 'utils/formatters/units/getReadableUnitName';
import { ExcessConsumptionChart } from '../charts/ExcessConsumptionChart';
import { ExcessConsumptionCuSUMChart } from '../charts/ExcessConsumptionCuSUMChart';
import { SelectInput } from '@components/inputs/SelectInput';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { useNavigate, useParams } from 'react-router-dom';
import { convertStringToInt } from 'utils/numberUtils/numberUtils';
import {
  type Change,
  type ListChanges,
  type TimeSeries,
} from 'interfaces/change/ChangeData.interface';
import { format } from 'date-fns';

const CHART_BUTTON_VALUES = {
  MEASURED: 'measured',
  EXCESS: 'excess',
  EXCESS_CUSUM: 'excess_cusum',
};

const orderListByDate = (array: DropdownOptions[]): DropdownOptions[] =>
  array.slice().sort((a, b) => {
    if (!a.name || !b.name) return 0;
    const dateA = new Date(
      a.name.replace(
        /(\d{2})\/(\d{2})\/(\d{4}) at (\d{2}):(\d{2})/,
        '$3-$2-$1T$4:$5'
      )
    );
    const dateB = new Date(
      b.name.replace(
        /(\d{2})\/(\d{2})\/(\d{4}) at (\d{2}):(\d{2})/,
        '$3-$2-$1T$4:$5'
      )
    );
    return dateB.getTime() - dateA.getTime();
  });
interface ChangesOverviewProps {
  data?: TimeSeries | null;
  connectionPointId?: string;
  isLoading?: boolean;
  unit?: string;
  dropdownData?: ListChanges | null;
}

const ChangesOverview: FC<ChangesOverviewProps> = ({
  data,
  connectionPointId,
  isLoading = false,
  unit = '',
  dropdownData,
}) => {
  const [activeChart, setActiveChart] = useState(CHART_BUTTON_VALUES.MEASURED);
  const [currentExtremes, setCurrentExtremes] = useState({ min: 0, max: 0 });
  const excessConsumptionChartRef = useRef(null);
  const measuredChartRef = useRef(null);
  const excessCusumChartRef = useRef(null);

  const navigate = useNavigate();

  const { id } = useParams();

  const buttonList = [
    {
      label: t('anomaliesDetailsPage.buttons.measuredVsExcepted'),
      value: CHART_BUTTON_VALUES.MEASURED,
      tooltip: t('anomaliesDetailsPage.toolTips.measuredVsExcepted'),
    },
    {
      label: t('anomaliesDetailsPage.buttons.excessConsumption'),
      value: CHART_BUTTON_VALUES.EXCESS,
      tooltip: t('anomaliesDetailsPage.toolTips.excessConsumption'),
    },
    {
      label: t('anomaliesDetailsPage.buttons.CuSUM'),
      value: CHART_BUTTON_VALUES.EXCESS_CUSUM,
      tooltip: t('anomaliesDetailsPage.toolTips.CuSUM'),
    },
  ];

  const dropdownChanges = useMemo(() => {
    if (!dropdownData?.changes_detected) return [];

    return dropdownData.changes_detected.map((change: Change) => ({
      name: format(new Date(change.moment_change), `dd/MM/yyyy 'at' hh:mm`),
      value: change.id,
    }));
  }, [dropdownData]);

  const sortedChanges = useMemo(
    () => orderListByDate(dropdownChanges as DropdownOptions[]),
    [dropdownChanges]
  );

  const handleDropdownChange = (connectionId?: string | number): void => {
    if (connectionId) {
      navigate(`/app/operations/anomalies/details/${connectionId}`);
    }
  };

  const handleActiveChart = (chartIndex: string): void => {
    setActiveChart(chartIndex);
  };

  const changesMap = useMemo(() => {
    if (data?.changes_map) {
      return Object.entries(data.changes_map).map(([id, date]) => ({
        date,
        id,
      }));
    }
    return [];
  }, [data]);

  return (
    <CardContainer>
      <div className="flex flex-col">
        <CardTitle
          style={{ fontSize: 14 }}
          title={t('anomaliesDetailsPage.changesOverview.title')}
          isLoading={isLoading}
          endAddorment={
            <div className="max-w-[230px] w-full">
              {isLoading ? (
                <Skeleton width={230} height={44} />
              ) : (
                <SelectInput
                  options={sortedChanges}
                  placeholder={t(
                    'anomaliesPage.placeholders.selectOtherChange'
                  )}
                  onChange={handleDropdownChange}
                  value={id ? (convertStringToInt(id) as number) : undefined}
                />
              )}
            </div>
          }
        />
        <ChartButton
          buttonList={buttonList}
          activeChart={activeChart}
          handleActiveChart={handleActiveChart}
          isLoading={isLoading}
        />
        <div className="mt-6">
          {isLoading ? (
            <Skeleton height={'300px'} />
          ) : (
            <>
              {activeChart === CHART_BUTTON_VALUES.MEASURED && (
                <MeasuredVsExpectedChart
                  ref={measuredChartRef}
                  id={connectionPointId}
                  data={data?.time_series}
                  changesData={changesMap}
                  momentChange={data?.current_change}
                  unit={getReadableUnitName(unit)}
                  setExtremes={setCurrentExtremes}
                  min={currentExtremes.min}
                  max={currentExtremes.max}
                />
              )}
              {activeChart === CHART_BUTTON_VALUES.EXCESS && (
                <ExcessConsumptionChart
                  ref={excessConsumptionChartRef}
                  id={connectionPointId}
                  data={data?.time_series}
                  changesData={changesMap}
                  momentChange={data?.current_change}
                  unit={getReadableUnitName(unit)}
                  setExtremes={setCurrentExtremes}
                  min={currentExtremes.min}
                  max={currentExtremes.max}
                />
              )}
              {activeChart === CHART_BUTTON_VALUES.EXCESS_CUSUM && (
                <ExcessConsumptionCuSUMChart
                  id={connectionPointId}
                  ref={excessCusumChartRef}
                  data={data?.time_series}
                  changesData={changesMap}
                  momentChange={data?.current_change}
                  unit={getReadableUnitName(unit)}
                  setExtremes={setCurrentExtremes}
                  min={currentExtremes.min}
                  max={currentExtremes.max}
                />
              )}
            </>
          )}
        </div>
      </div>
    </CardContainer>
  );
};
export default ChangesOverview;
