import { Modal } from '@components/modal';
import { useChangePasswordDemoAccount } from 'api/sysadmin/sysAdminServices';
import useHRForm from 'hooks/useHRForm';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import PasswordRandomizerField from '../../components/PasswordRandomizerField/PasswordRandomizerField';
import {
  changeDemoAccountPasswordNames,
  changeDemoAccountPasswordSchema,
} from './schema';

interface ChangeDemoAccountPasswordModalProps extends Disclosure {
  selectedId?: number;
}

const ChangeDemoAccountPasswordModal: FC<
  ChangeDemoAccountPasswordModalProps
> = ({ selectedId, isOpen, handleClose }) => {
  const { methods } = useHRForm({ schema: changeDemoAccountPasswordSchema });
  const { mutate, isPending, isSuccess } =
    useChangePasswordDemoAccount(selectedId);

  const handleChangeDemoAccountPassword = (): void => {
    const body = methods.getValues();
    mutate(body);
  };

  useEffect(() => {
    if (isOpen) {
      methods.reset();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      actionBtns={{
        primary: {
          label: 'Confirm',
          onClick: methods.handleSubmit(handleChangeDemoAccountPassword),
          isLoading: isPending,
        },
        secondary: { label: 'Cancel' },
      }}
      maxWidth={500}
      title={'Change Demo account password'}
    >
      <FormProvider {...methods}>
        <div>
          <PasswordRandomizerField
            name={changeDemoAccountPasswordNames.newPassword}
          />
        </div>
      </FormProvider>
    </Modal>
  );
};
export default ChangeDemoAccountPasswordModal;
