import { Modal } from '@components/modal';
import { useFetchHeatMap } from 'api/changes/changesServices';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC } from 'react';
import { t } from 'i18next';
import { format } from 'date-fns';
import { HeatMap } from '@components/heatMap';
import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';

interface HeatMapModalProps extends Disclosure {
  connectionPointIdClient?: string;
  momentChange?: string;
  previousChange?: string | null;
  nextChange?: string | null;
  unit?: string;
}

const HeatMapModal: FC<HeatMapModalProps> = ({
  isOpen,
  handleClose,
  handleOpen,
  connectionPointIdClient,
  previousChange,
  momentChange,
  nextChange,
  unit,
}) => {
  const { data } = useFetchHeatMap(connectionPointIdClient, momentChange);

  return (
    <Modal
      title={t('anomaliesDetailsPage.heatMapModal.title')}
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      position="center"
      maxWidth={1200}
    >
      {data && data?.heatmap?.length > 0 ? (
        <>
          <div className="flex gap-2">
            <p>
              {t('anomaliesDetailsPage.heatMapModal.description', {
                currentDateChange: momentChange
                  ? format(new Date(momentChange), 'dd/MM/yyyy')
                  : momentChange,
                previousChange: previousChange
                  ? format(new Date(previousChange), 'dd/MM/yyyy')
                  : format(new Date(momentChange ?? ''), 'dd/MM/yyyy'),
                nextChange: nextChange
                  ? format(new Date(nextChange), 'dd/MM/yyyy')
                  : format(new Date(), 'dd/MM/yyyy'),
              })}
            </p>
            <TooltipIconComponent
              content={t('anomaliesDetailsPage.heatMapModal.tooltip')}
            />
          </div>
          <div className="pt-4 pb-8">
            <small>{t('anomaliesDetailsPage.heatMapModal.decimalInfo')}</small>
          </div>
          <div>
            <HeatMap heatMapData={data?.heatmap} unit={unit} />
          </div>
        </>
      ) : (
        <>
          <p>{t('anomaliesDetailsPage.heatMapModal.noData')}</p>
        </>
      )}
    </Modal>
  );
};
export default HeatMapModal;
