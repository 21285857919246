import { Modal } from '@components/modal';
import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { ImpactWrapper } from '@components/wrappers/ImpactWrapper';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type ProjectBaseline } from 'interfaces/projects/ProjectData.interface';
import { CardTitle } from 'layout/Card';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { numericFormatter } from 'react-number-format';
import { convertToReadableDate } from 'utils/dateUtils/dateUtils';
import {
  removeUnderlineAndAddWhitespace,
  stringToCapitalize,
} from 'utils/formatters/string/stringFormatter';
import { IPMPVCard } from '../IPMVPCard';

interface ModelAndSavingsDetailsModalProps extends Disclosure {
  referencePeriodStart?: string;
  referencePeriodEnd?: string;
  isLoading: boolean;
  data?: ProjectBaseline;
}

const ModelAndSavingsDetailsModal: FC<ModelAndSavingsDetailsModalProps> = ({
  isOpen,
  handleClose,
  handleOpen,
  referencePeriodStart,
  referencePeriodEnd,
  isLoading,
  data,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('projectsDetailsPage.modelAndSavingsDetails.title')}
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      position="center"
      width={'95vw'}
      maxWidth={1200}
    >
      <p className="text-base">
        {t('projectsDetailsPage.modelAndSavingsDetails.modalDescription')}
      </p>
      <div className="flex gap-6 mt-4">
        <div className="w-1/2">
          <div className="flex flex-col p-6 border-2 border-gray-100 rounded-lg w-full bg-white">
            <CardTitle title="Module quality" className="mb-1" />
            <div className="flex gap-2">
              {isLoading ? (
                <Skeleton width={'100px'} />
              ) : (
                <p>{t('projectsDetailsPage.referencePeriod')}:</p>
              )}
              {isLoading ? (
                <Skeleton width={'150px'} />
              ) : (
                <p>
                  {referencePeriodStart &&
                    convertToReadableDate(referencePeriodStart, {
                      onlyDate: true,
                    })}{' '}
                  -{' '}
                  {referencePeriodEnd &&
                    convertToReadableDate(referencePeriodEnd, {
                      onlyDate: true,
                    })}
                </p>
              )}
            </div>
            <div className="mt-6">
              <h3 className="text-base text-gray-60">
                {isLoading ? (
                  <Skeleton width={'150px'} />
                ) : (
                  t(
                    'projectsDetailsPage.modelAndSavingsDetails.statisticalParameters'
                  )
                )}
              </h3>
              <div className="w-full mt-2">
                <table className="w-full table-fixed">
                  <tbody className="">
                    <tr className="bg-gray-10">
                      <td className="py-2 flex items-center gap-2 px-6 text-base text-gray-60">
                        CVRMSE
                        <TooltipIconComponent
                          content={t(
                            'projectsDetailsPage.modelAndSavingsDetails.tooltips.cvrmse'
                          )}
                        />
                      </td>
                      <td className="py-2 px-6 text-right ">
                        {data?.cvrmse
                          ? numericFormatter((data.cvrmse * 100).toString(), {
                              decimalSeparator: ',',
                              thousandSeparator: '.',
                              decimalScale: 0,
                            })
                          : 0}{' '}
                        %
                      </td>
                    </tr>
                    <tr className="bg-white">
                      <td className="flex items-center gap-2 py-2 px-6 text-base text-gray-60">
                        NMBE
                        <TooltipIconComponent
                          content={t(
                            'projectsDetailsPage.modelAndSavingsDetails.tooltips.nmbe'
                          )}
                        />
                      </td>
                      <td className="py-2 px-6 text-right">
                        {data?.nmbe
                          ? numericFormatter((data.nmbe * 100).toFixed(2), {
                              decimalSeparator: ',',
                              thousandSeparator: '.',
                              decimalScale: 2,
                            })
                          : 0}{' '}
                        %
                      </td>
                    </tr>
                    {/* Temporarily removed due to incorrect MSE calculation */}
                    <tr className="bg-gray-10 hidden">
                      <td className="flex items-center gap-2 py-2 px-6 text-base text-gray-60">
                        MSE
                        <TooltipIconComponent
                          content={t(
                            'projectsDetailsPage.modelAndSavingsDetails.tooltips.mse'
                          )}
                        />
                      </td>
                      <td className=" py-2 px-6 text-right">
                        {data?.mse
                          ? numericFormatter(data.mse.toFixed(0), {
                              decimalSeparator: ',',
                              thousandSeparator: '.',
                              decimalScale: 0,
                            })
                          : 0}
                      </td>
                    </tr>
                    <tr className="bg-gray-10">
                      <td className="flex items-center gap-2 py-2 px-6 text-base text-gray-60">
                        R&sup2;
                        <TooltipIconComponent
                          content={t(
                            'projectsDetailsPage.modelAndSavingsDetails.tooltips.r2'
                          )}
                        />
                      </td>
                      <td className="py-2 px-6 text-right">
                        {data?.r2
                          ? numericFormatter((data.r2 * 100).toFixed(0), {
                              decimalSeparator: ',',
                              thousandSeparator: '.',
                              decimalScale: 0,
                            })
                          : 0}{' '}
                        %
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-4">
              <h3 className="text-base text-gray-60">
                {isLoading ? (
                  <Skeleton width={'150px'} />
                ) : (
                  t('projectsDetailsPage.modelAndSavingsDetails.variables')
                )}
              </h3>
              <div className="w-full mt-2">
                <table className="w-full table-fixed">
                  <tbody className="">
                    {data?.features?.map?.((featureVariables, index) => {
                      return (
                        <tr
                          key={featureVariables.feature}
                          className={index % 2 ? 'bg-white' : 'bg-gray-10'}
                        >
                          <td className="py-2 px-6 text-base text-gray-60">
                            {stringToCapitalize(
                              removeUnderlineAndAddWhitespace(
                                featureVariables.feature
                              )
                            )}
                          </td>
                          <td className=" py-2 px-6 text-right">
                            {numericFormatter(
                              (
                                featureVariables.feature_importance * 100
                              ).toFixed(0),
                              {
                                decimalSeparator: ',',
                                thousandSeparator: '.',
                                decimalScale: 0,
                              }
                            )}{' '}
                            %
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <IPMPVCard variant={data?.IPMVP_proof_baseline} />
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex flex-col p-6 border-2 border-gray-100 rounded-lg w-full bg-white">
            <CardTitle title="Savings uncertainty" />
            <div className="mt-4 flex flex-col p-6 border border-gray-100 rounded-lg w-full bg-white">
              <div className="flex justify-between gap-4">
                <ImpactWrapper
                  label={'Total'}
                  value={data?.savings_uncertainty_total}
                  endAddorment={'kWh'}
                  isLoading={isLoading}
                  numericColorVisualization={{ isGray: true }}
                />
                <ImpactWrapper
                  label={'Total in %'}
                  value={
                    data?.savings_uncertainty_rel
                      ? data.savings_uncertainty_rel * 100
                      : 0
                  }
                  endAddorment={'%'}
                  isLoading={isLoading}
                  decimalScale={0}
                  numericColorVisualization={{ isGray: true }}
                />
              </div>
            </div>
            <IPMPVCard
              variant={data?.IPMVP_proof_savings}
              isUncertainty={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ModelAndSavingsDetailsModal;
