import { InfoIcon } from '@components/icons/InfoIcon';
import ThumbsDownIcon from '@components/icons/ThumbsDownIcon/ThumbsDownIcon';
import ThumbsUpIcon from '@components/icons/ThumbsUpIcon/ThumbsUpIcon';
import Tippy from '@tippyjs/react';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type IPMVPVariants } from 'interfaces/projects/ProjectData.interface';
import { type FC, type ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IPMPVCardProps {
  variant?: IPMVPVariants;
  isUncertainty?: boolean;
}

const IPMPVCard: FC<IPMPVCardProps> = ({ variant, isUncertainty = false }) => {
  const [properties, setProperties] = useState<{
    bgColor?: string;
    icon?: ReactNode;
    tooltip?: string;
  }>({
    bgColor: undefined,
    icon: undefined,
    tooltip: undefined,
  });
  const { theme } = useTheme();
  const { t } = useTranslation();

  useMemo(() => {
    switch (variant) {
      case 'ORANGE':
        setProperties({
          bgColor: theme.colors.ipmpv.background.uncertainty,
          icon: <ThumbsDownIcon fill={theme.colors.ipmpv.icon.uncertainty} />,
          tooltip: isUncertainty
            ? t('projectsPage.ipmvp.uncertainty.fail')
            : t('projectsPage.ipmvp.fail'),
        });

        break;
      case 'RED':
        setProperties({
          bgColor: theme.colors.ipmpv.background.fail,
          icon: <ThumbsDownIcon fill={theme.colors.ipmpv.icon.fail} />,
          tooltip: isUncertainty
            ? t('projectsPage.ipmvp.uncertainty.fail')
            : t('projectsPage.ipmvp.fail'),
        });

        break;
      case 'GREEN':
        setProperties({
          bgColor: theme.colors.ipmpv.background.success,
          icon: <ThumbsUpIcon fill={theme.colors.ipmpv.icon.success} />,
          tooltip: isUncertainty
            ? t('projectsPage.ipmvp.uncertainty.success')
            : t('projectsPage.ipmvp.success'),
        });

        break;

      default:
        break;
    }
  }, [variant]);

  return (
    <>
      {variant && (
        <div
          className="mt-6 flex p-4 rounded-lg w-full items-center justify-between"
          style={{
            backgroundColor: properties.bgColor,
          }}
          data-proof-variant={variant}
        >
          <div className="flex items-center">
            <div className="px-4 text-base text-gray-60 font-bold">
              IPMVP-proof
            </div>
            <div>{properties.icon}</div>
          </div>
          <Tippy content={properties.tooltip}>
            <span>
              <InfoIcon fill={theme.colors['gray-60']} />
            </span>
          </Tippy>
        </div>
      )}
    </>
  );
};
export default IPMPVCard;
