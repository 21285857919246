import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { normalizeFieldValue } from 'utils/normalizeFieldValue';
import { type ReactNode } from 'react';
import { getDaysFromDate } from 'utils/formatters/getDaysFromDate/getDaysFromDate';
import { type UserResponse } from 'interfaces/user/UserResponse.interface';

interface UserTableColumnsReturnType {
  columns: ColumnDef<any, any>[];
}

const useGetUserTableColumns = ({
  createTableActions,
}: {
  createTableActions: (id: number) => ReactNode;
}): UserTableColumnsReturnType => {
  const columnHelper = createColumnHelper<UserResponse>();

  const columns = [
    columnHelper.accessor('given_name', {
      header: () => 'Name',
      cell: (info) => {
        const values = info.row.original;
        return `${values.given_name} ${values.family_name}`;
      },
      enableSorting: true,
      maxSize: 350,
      size: 350,
    }),
    columnHelper.accessor('email', {
      header: () => 'Email',
      cell: (info) => info.renderValue(),
      maxSize: 280,
      size: 280,
    }),
    columnHelper.accessor('role', {
      header: () => 'Role',
      cell: (info) => normalizeFieldValue(info.renderValue() ?? ''),
    }),
    columnHelper.accessor('function', {
      header: () => 'Function',
      cell: (info) => normalizeFieldValue(info.renderValue() ?? ''),
    }),
    columnHelper.accessor('last_activity', {
      header: () => 'Last activity',
      cell: (info) =>
        info.getValue() ? getDaysFromDate(info.renderValue() ?? '') : '-',
    }),
    columnHelper.accessor('id', {
      enableSorting: false,
      header: undefined,
      id: 'action',
      cell: (info) => createTableActions(info.row.original.id),
      size: 15,
    }),
  ];

  return {
    columns,
  };
};
export default useGetUserTableColumns;
