import SettingsIcon from 'components/icons/MenuIcons/SettingsIcon';
import MenuLogoIcon from 'components/icons/MenuLogoIcon/MenuLogoIcon';
import MenuLogoIconExpanded from 'components/icons/MenuLogoIcon/MenuLogoIconExpanded';
import { MenuOptionsEnum } from 'enums/MenuOptionsEnum';
import { type FC, useEffect, useRef, useState } from 'react';
import SideMenuButton from './components/SideMenuButton/SideMenuButton';
import { StyledSideMenu } from './styled';
import { IoIosArrowBack } from 'react-icons/io';
import { type MenuOptions } from 'hooks/sideMenu/useGetMenuOptions';
import { useTranslation } from 'react-i18next';
import { PhoneIcon } from '@components/icons/PhoneIcon';
import { useAuth } from 'hooks/contexts/AuthContext';
import { RoleEnum } from 'enums/RoleEnum';
import { useActiveSideMenu } from 'context/ActiveSideMenuProvider/ActiveSideMenuProvider';

interface SideMenuProps {
  menuOptions: MenuOptions[];
}

const SideMenu: FC<SideMenuProps> = ({ menuOptions }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const sideMenuRef = useRef<HTMLDivElement | null>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [activeMenu, setActiveMenu] = useState<MenuOptionsEnum>(
    MenuOptionsEnum.UNKNOWN_OPTION
  );
  const { activeMenuOption } = useActiveSideMenu();

  useEffect(() => {
    setActiveMenu(activeMenuOption);
  }, [activeMenuOption]);

  const [currentExpandedMenu, setCurrentExpandedMenu] =
    useState<MenuOptionsEnum>(MenuOptionsEnum.UNKNOWN_OPTION);

  const handleDrawerExpansion = (): void => {
    if (currentExpandedMenu !== MenuOptionsEnum.UNKNOWN_OPTION)
      setCurrentExpandedMenu(MenuOptionsEnum.UNKNOWN_OPTION);
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        sideMenuRef.current &&
        !sideMenuRef.current.contains(event.target as Node)
      ) {
        setIsExpanded(false);
        setCurrentExpandedMenu(MenuOptionsEnum.UNKNOWN_OPTION);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMenuExpansion = (option: MenuOptionsEnum): void => {
    if (!isExpanded) handleDrawerExpansion();
    if (currentExpandedMenu === option) {
      setCurrentExpandedMenu(MenuOptionsEnum.UNKNOWN_OPTION);
      return;
    }
    setCurrentExpandedMenu(option);
  };

  return (
    <div className="flex w-[88px] shrink-0" ref={sideMenuRef}>
      <StyledSideMenu $isExpanded={isExpanded} data-testid="side-menu">
        <div className="py-6 mb-6" onClick={handleDrawerExpansion}>
          {isExpanded ? (
            <div className="flex items-center px-2">
              <MenuLogoIconExpanded
                className="mx-auto"
                data-testid="menu-logo-expanded"
              />
              <IoIosArrowBack
                className="cursor-pointer"
                data-testid="collapse-menu-btn"
              />
            </div>
          ) : (
            <div className="px-5 ">
              <MenuLogoIcon className="mx-auto" data-testid="menu-logo-mini" />
            </div>
          )}
        </div>
        <div className="flex flex-col flex-1 items-center justify-start w-full gap-y-9 pb-4">
          {menuOptions.map(
            (button) =>
              !button.hide && (
                <SideMenuButton
                  key={button.option}
                  Icon={button.icon}
                  isDrawerExpanded={isExpanded}
                  handleMenuExpansion={handleMenuExpansion}
                  currentExpandedMenuOption={currentExpandedMenu}
                  activeMenu={activeMenu}
                  menuOption={button.option}
                  label={button.optionLabel}
                  incoming={button.incoming}
                  subMenuList={button.subMenu}
                  linkTo={button.linkTo}
                />
              )
          )}
          <div className="flex gap-8 w-full mt-auto flex-col">
            {user?.role === RoleEnum.SYSADMIN && (
              <SideMenuButton
                Icon={PhoneIcon}
                isDrawerExpanded={isExpanded}
                handleMenuExpansion={handleMenuExpansion}
                currentExpandedMenuOption={currentExpandedMenu}
                activeMenu={activeMenu}
                menuOption={MenuOptionsEnum.ADMIN}
                linkTo="admin"
                label={t('sideMenu.systemAdmin')}
              />
            )}
            <SideMenuButton
              Icon={SettingsIcon}
              isDrawerExpanded={isExpanded}
              handleMenuExpansion={handleMenuExpansion}
              currentExpandedMenuOption={currentExpandedMenu}
              activeMenu={activeMenu}
              menuOption={MenuOptionsEnum.SETTINGS}
              linkTo="settings"
              label={t('common.settings')}
            />
          </div>
        </div>
      </StyledSideMenu>
    </div>
  );
};
export default SideMenu;
