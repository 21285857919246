import { useActiveSideMenu } from 'context/ActiveSideMenuProvider/ActiveSideMenuProvider';
import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { type FC, useEffect, useMemo } from 'react';
import { useRoutes } from 'react-router-dom';
import generateRoutes from '../generateRoutes';

const Router: FC = () => {
  const token = localStorage.getItem('jwtToken');
  const { hasFeatureFlagPermission } = useFeatureFlag();
  const { setActiveRoute } = useActiveSideMenu();

  const isAuthenticated = useMemo(() => !!token, [token]);
  const routes = generateRoutes(isAuthenticated, hasFeatureFlagPermission);

  useEffect(() => {
    if (routes && routes.length > 0) {
      routes.forEach((route) => {
        if (route.path === '/app') {
          route.children?.forEach((child) => {
            if (
              child?.path &&
              location.pathname.includes(`/app/${child.path}`)
            ) {
              setActiveRoute(child.path.toUpperCase());
            }
          });
        }
      });
    }
  }, [location, routes]);

  // setRoutes(routes);

  return useRoutes(routes);
};

export default Router;
