import axiosInstance from 'api/axios';
import { type AxiosError } from 'axios';
import {
  type ImpersonatedUser,
  type SysAdminUserList,
} from 'interfaces/user/UserResponse.interface';
import {
  type InfiniteData,
  type UseInfiniteQueryResult,
  type UseMutationResult,
  type UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { handleError } from 'utils/handleError/handleError';
import { handleSuccess } from 'utils/handleSuccess/handleSuccess';
import {
  type FeatureFlag,
  type FeatureFlagListResponse,
} from 'interfaces/featureFlags/FeatureFlags.interface';
import queryClient from 'lib/queryClient';
import { type CompanyListResponse } from 'interfaces/company/Company.interface';
import {
  type LuzmoDashboardDetailsResponse,
  type LuzmoDashboardFindResponse,
  type LuzmoDashboardListResponse,
  type LuzmoDashboardProxyResponse,
} from 'interfaces/luzmo/Luzmo.interface';
import { type PermissionFlagListResponse } from 'interfaces/permission/Permission.interface';
import {
  type DemoAccountSchemaType,
  type EditDemoAccountSchemaType,
} from 'pages/SysAdminPage/DemoAccountsPage/form/schema';
import { type FetchUserResponse } from 'api/user/userService';
import { type ChangeDemoAccountPasswordSchemaType } from 'pages/SysAdminPage/DemoAccountsPage/modal/ChangeDemoAccountPasswordModal/schema';

interface CreateLuzmoDashboardPayload {
  internal_name: string;
  luzmo_name?: string | undefined;
  is_universal: boolean;
  companies?: { id: number | string | undefined }[];
  filters: { filters: string[] };
}

export interface PatchPermissionRolePayload {
  id: number;
  admin: boolean;
  reader: boolean;
  responsible: boolean;
  manager: boolean;
}

export const useFetchSysAdminAllUsers = ({
  query,
  role,
  orderBy,
  companyName,
}: {
  query?: string;
  role?: string;
  orderBy?: string;
  companyName?: string;
}): UseInfiniteQueryResult<InfiniteData<SysAdminUserList>> => {
  return useInfiniteQuery<SysAdminUserList>({
    queryKey: ['sysAdminUsersList', query, role, orderBy, companyName],
    queryFn: async ({ pageParam = 1 }) => {
      const params = {
        page: pageParam,
        per_page: 20,
        query,
        role,
        order_by: orderBy,
        company_name: companyName,
      };
      if (!params.role) {
        delete params.role;
      }
      if (!params.query) {
        delete params.query;
      }
      if (!params.order_by) {
        delete params.order_by;
      }
      if (!params.company_name) {
        delete params.company_name;
      }
      const response = await axiosInstance.get(`sys/user/list`, {
        params,
      });
      return response.data;
    },
    initialPageParam: 1,
    getNextPageParam: (_lastGroup, groups) => groups.length + 1,
    refetchOnWindowFocus: false,
  });
};

export const useImpersonateUser = (): UseMutationResult<
  ImpersonatedUser | null,
  Error | AxiosError,
  number
> => {
  return useMutation({
    mutationFn: async (id) => {
      if (!id) {
        return null;
      }
      const response = await axiosInstance.post(`impersonation/${id}`);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('User impersonated successfully');
    },
  });
};

export const useListPermissionMap = ({
  query,
  perPage = 10,
}: {
  query?: string;
  perPage?: number;
}): UseInfiniteQueryResult<InfiniteData<PermissionFlagListResponse>> => {
  return useInfiniteQuery<PermissionFlagListResponse>({
    queryKey: ['permissionMapList', query, perPage],
    queryFn: async ({ pageParam }) => {
      const response = await axiosInstance.get(
        `sys/feature_flag/permission_map/`,
        { params: { query, per_page: perPage } }
      );
      return response.data;
    },
    initialPageParam: 1,
    getNextPageParam: (_lastGroup, groups) => groups.length + 1,
    refetchOnWindowFocus: false,
  });
};

export const useEditRolePermissions = (): UseMutationResult<
  void,
  Error | AxiosError,
  PatchPermissionRolePayload[]
> => {
  return useMutation({
    mutationFn: async (body) => {
      const response = await axiosInstance.patch(`sys/permission_map/`, body);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('Role permissions updated successfully');
      await queryClient.invalidateQueries({
        queryKey: ['permissionMapList'],
      });
    },
  });
};

export const useListFeatureMap = (): UseInfiniteQueryResult<
  InfiniteData<FeatureFlagListResponse>
> => {
  return useInfiniteQuery<FeatureFlagListResponse>({
    queryKey: ['featureMapList'],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await axiosInstance.get(`sys/feature_flag/filter`);
      return response.data;
    },
    initialPageParam: 1,
    getNextPageParam: (_lastGroup, groups) => groups.length + 1,
    refetchOnWindowFocus: false,
  });
};

export const useCreateFeatureFlag = (): UseMutationResult<
  ImpersonatedUser | null,
  Error | AxiosError
> => {
  return useMutation({
    mutationFn: async (body) => {
      const response = await axiosInstance.post(`sys/feature_flag/`, body);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('Feature flag created successfully');
      await queryClient.invalidateQueries({
        queryKey: ['featureMapList'],
      });
    },
  });
};

export const useDeleteFeatureFlag = (): UseMutationResult<
  ImpersonatedUser | null,
  Error | AxiosError,
  number
> => {
  return useMutation({
    mutationFn: async (id) => {
      const response = await axiosInstance.delete(`sys/feature_flag/${id}`);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('Feature flag deleted successfully');
      await queryClient.invalidateQueries({
        queryKey: ['featureMapList'],
      });
    },
  });
};

export const useFetchFeatureFlagDetail = (
  id?: number
): UseQueryResult<FeatureFlag> => {
  return useQuery<FeatureFlag>({
    queryKey: ['featureMapDetails', id],
    queryFn: async () => {
      if (!id) {
        return;
      }
      const response = await axiosInstance.get(`sys/feature_flag/${id}`);
      return response.data;
    },
  });
};

export const useEditFeatureFlagDetail = (id?: number): UseMutationResult => {
  return useMutation({
    mutationFn: async (body) => {
      if (!id) {
        return;
      }
      const response = await axiosInstance.patch(
        `sys/feature_flag/${id}`,
        body
      );
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('Feature flag updated successfully');
      await queryClient.invalidateQueries({
        queryKey: ['featureMapList'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['featureMapDetails'],
      });
    },
  });
};

export const useFetchCompanies = ({
  query,
  perPage = 10,
}: {
  query?: string;
  perPage?: number;
}): UseQueryResult<CompanyListResponse> => {
  return useQuery<CompanyListResponse>({
    queryKey: ['fetchCompanies', query, perPage],
    queryFn: async () => {
      const response = await axiosInstance.get(`sys/feature_flag/company/`, {
        params: { query, per_page: perPage },
      });
      return response.data;
    },
  });
};

export const useFetchLuzmoReportsList = (): UseInfiniteQueryResult<
  InfiniteData<LuzmoDashboardListResponse>
> => {
  return useInfiniteQuery<LuzmoDashboardListResponse>({
    queryKey: ['luzmoList'],
    queryFn: async () => {
      const response = await axiosInstance.get('sys/luzmo_dashboard/');
      return response.data;
    },
    initialPageParam: 1,
    getNextPageParam: (_lastGroup, groups) => groups.length + 1,
    refetchOnWindowFocus: false,
  });
};

export const useSearchLuzmoName = ({
  query,
  perPage = 10,
}: {
  query?: string;
  perPage?: number;
}): UseQueryResult<LuzmoDashboardFindResponse> => {
  return useQuery<LuzmoDashboardFindResponse>({
    queryKey: ['searchLuzmoName', query, perPage],
    queryFn: async () => {
      const params = { query, per_page: perPage };
      if (!query) {
        delete params.query;
      }
      const response = await axiosInstance.get(
        'sys/luzmo_dashboard/proxy/list',
        {
          params,
        }
      );
      return response.data;
    },
  });
};

export const useFindLuzmoDashboard = (
  luzmoName: string
): UseQueryResult<LuzmoDashboardProxyResponse> => {
  return useQuery<LuzmoDashboardProxyResponse>({
    queryKey: ['findLuzmoDashboard', luzmoName],
    queryFn: async () => {
      const parsedLuzmoName = luzmoName.substring(0, luzmoName.length - 6);

      const response = await axiosInstance.get(
        `sys/luzmo_dashboard/proxy/find/${parsedLuzmoName}`
      );
      return response.data;
    },
  });
};

export const useFetchLuzmoDashboard = (
  id?: number
): UseQueryResult<LuzmoDashboardDetailsResponse> => {
  return useQuery<LuzmoDashboardDetailsResponse>({
    queryKey: ['fetchLuzmoDashboard', id],
    queryFn: async () => {
      if (!id) return;

      const response = await axiosInstance.get(`sys/luzmo_dashboard/${id}`);
      return response.data;
    },
  });
};

export const useEditLuzmoDashboard = (id?: number): UseMutationResult => {
  return useMutation({
    mutationFn: async (body) => {
      if (!id) return;
      const response = await axiosInstance.patch(
        `sys/luzmo_dashboard/${id}`,
        body
      );
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('Feature flag updated successfully');
      await queryClient.invalidateQueries({
        queryKey: ['luzmoList'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['findLuzmoDashboard'],
      });
    },
  });
};

export const useDeleteLuzmoDashboard = (): UseMutationResult<
  void,
  Error | AxiosError,
  number | undefined
> => {
  return useMutation({
    mutationFn: async (id) => {
      if (!id) return;
      const response = await axiosInstance.delete(`sys/luzmo_dashboard/${id}`);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['luzmoList'],
      });
      handleSuccess('Feature flag updated successfully');
    },
  });
};

export const useCreateLuzmoDashboard = (): UseMutationResult<
  LuzmoDashboardDetailsResponse,
  Error | AxiosError,
  CreateLuzmoDashboardPayload
> => {
  return useMutation({
    mutationFn: async (body) => {
      if (body?.luzmo_name) {
        const parsedLuzmoName = body.luzmo_name.substring(
          0,
          body.luzmo_name.length - 6
        );
        body.luzmo_name = parsedLuzmoName;
      }

      const response = await axiosInstance.post(`sys/luzmo_dashboard/`, body);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('Feature flag updated successfully');
      await queryClient.invalidateQueries({
        queryKey: ['luzmoList'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['findLuzmoDashboard'],
      });
    },
  });
};

export const useFetchDemoAccount = (
  id?: number
): UseQueryResult<FetchUserResponse> => {
  return useQuery<FetchUserResponse>({
    queryKey: ['fetchDemoAccount', id],
    queryFn: async () => {
      if (!id) return;

      const response = await axiosInstance.get(`demo/${id}`);
      return response.data;
    },
  });
};

export const useCreateDemoAccount = (): UseMutationResult<
  void,
  Error | AxiosError,
  DemoAccountSchemaType
> => {
  return useMutation({
    mutationFn: async (body) => {
      const response = await axiosInstance.post(`demo/register`, body);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['sysAdminUsersList'],
      });
      handleSuccess('Demo account created successfully');
    },
  });
};

export const useEditDemoAccount = (
  id?: number
): UseMutationResult<void, Error | AxiosError, EditDemoAccountSchemaType> => {
  return useMutation({
    mutationFn: async (body) => {
      if (!id) return;
      const { email, ...rest } = body;
      const response = await axiosInstance.patch(`demo/${id}`, rest);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['sysAdminUsersList'],
      });
      handleSuccess('Demo account updated successfully');
    },
  });
};

export const useChangePasswordDemoAccount = (
  id?: number
): UseMutationResult<
  void,
  Error | AxiosError,
  ChangeDemoAccountPasswordSchemaType
> => {
  return useMutation({
    mutationFn: async (body) => {
      if (!id) return;
      const response = await axiosInstance.patch(
        `demo/change_password/${id}`,
        body
      );
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['sysAdminUsersList'],
      });
      handleSuccess('Demo account password updated successfully');
    },
  });
};

export const useDeleteDemoAccount = (): UseMutationResult<
  void,
  Error | AxiosError<unknown, any>,
  string | number,
  unknown
> => {
  return useMutation({
    mutationFn: async (id: string | number) => {
      await axiosInstance.delete(`demo/${id}`);
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('User deleted succesfully');
      await queryClient.invalidateQueries({ queryKey: ['sysAdminUsersList'] });
    },
  });
};
