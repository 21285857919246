import { Modal } from 'components/modal';
import { type FC, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import ManageUserForm from '../../forms/ManageUserForm/ManageUserForm';
import useHRForm from 'hooks/useHRForm';
import { manageUserSchema } from 'pages/SettingsPage/forms/ManageUserForm/schema';
import { useCreateUser } from 'api/user/userService';
import { t } from 'i18next';
import { useListMeters } from 'api/assets/assetsServices';
import { type Meter } from 'interfaces/assets/Meters.interface';
import useDisclosure from 'hooks/useDisclosure';
import { ConfirmModal } from '@components/confirmModal';

interface AddUserModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  companyId: number | undefined | null;
}

const initialValues = {
  email: '',
  given_name: '',
  family_name: '',
  phone_number: '',
  role: '',
  function: '',
  language: 'EN',
  meters: [],
  groups: [],
  assets: [],
};

const AddUserModal: FC<AddUserModalProps> = ({
  isOpen,
  handleClose,
  handleOpen,
  companyId,
}) => {
  const {
    isOpen: isConfirmationOpen,
    handleClose: handleConfirmationClose,
    handleOpen: handleConfirmationOpen,
  } = useDisclosure();
  const { methods } = useHRForm({
    schema: manageUserSchema,
    initialValues,
  });

  const [metersQuery, setMetersQuery] = useState('');

  const { data: meterList, isFetching: isFetchingMeterList } = useListMeters({
    query: metersQuery,
    perPage: 999,
  });

  const meterOptions = useMemo(
    () =>
      meterList?.pages?.flatMap(
        (page) =>
          page?.meters.map((meter: Meter) => ({
            name: meter.name,
            value: meter.id,
          }))
      ) ?? [],
    [meterList]
  );

  const {
    mutate: createUserMutate,
    isPending,
    isSuccess,
  } = useCreateUser(companyId);

  const handleAddUser = (): void => {
    const body = methods.getValues();

    const parsedMetersId =
      body.meters?.length > 0
        ? body.meters
            ?.filter((meter) => meter.value !== 'selectAll')
            .map((meter) => ({ id: meter.value }))
        : meterOptions.map((meter) => ({ id: meter.value }));
    const parsedGroupsId = body.groups
      ?.filter((group) => group.value !== 'selectAll')
      .map((group) => ({ id: group.value }));
    const parsedAssetsId = body.assets
      ?.filter((asset) => asset.value !== 'selectAll')
      .map((asset) => ({ id: asset.value }));
    const params = {
      email: methods.getValues('email'),
      given_name: methods.getValues('given_name'),
      family_name: methods.getValues('family_name'),
      phone_number: methods.getValues('phone_number'),
      role: methods.getValues('role'),
      function: methods.getValues('function'),
      language: 'EN',
      meters: parsedMetersId,
      assets: parsedAssetsId,
      groups: parsedGroupsId,
    };

    methods.clearErrors();

    createUserMutate(params);
  };

  const closeModal = (): void => {
    methods.reset({}, { keepValues: false });
    handleClose();
  };

  const validateFormAndProceed = async (): Promise<void> => {
    const isValid = await methods.trigger();

    if (!isValid) {
      return;
    }
    const payload = methods.getValues();

    if (payload?.meters?.length > 0) {
      handleAddUser();
      return;
    }
    handleConfirmationOpen();
  };

  useEffect(() => {
    if (isSuccess) {
      methods.reset({}, { keepValues: false });
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      onOpen={handleOpen}
      actionBtns={{
        primary: {
          label: t('settingsPage.usersTab.buttons.inviteUser'),
          onClick: validateFormAndProceed,
          isLoading: isPending,
        },
        secondary: { label: t('common.buttons.cancel') },
      }}
      title={t('settingsPage.usersTab.buttons.inviteNewUser')}
    >
      <FormProvider {...methods}>
        <ManageUserForm
          isLoading={isPending}
          setMetersQuery={setMetersQuery}
          meterOptions={meterOptions}
          isFetchingMeterList={isFetchingMeterList}
          metersQuery={metersQuery}
        />
      </FormProvider>
      <ConfirmModal
        isOpen={isConfirmationOpen}
        handleClose={handleConfirmationClose}
        contentText={
          "You haven't selected any meters. If no meters are selected, all meters will be assigned to this user. Do you want to proceed?"
        }
        onConfirm={() => {}}
        actionBtns={{
          primary: {
            label: 'Yes',
            onClick: () => {
              handleAddUser();
              handleConfirmationClose();
            },
          },
          secondary: { label: 'No' },
        }}
      />
    </Modal>
  );
};
export default AddUserModal;
